

















/* item */


.download_item_icon_div{
    width: auto;
}

.download_item_icon{
    width: auto;
    height: auto;
    
}

.download_item_name_div{
    width: 100%;
    margin-right: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    max-height: 98%;
    height: auto;
    break-inside: auto;
    overflow: hidden;

}

