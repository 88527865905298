


.download_mess_frame{
    width: 92%;
    padding-left: 4%;
    padding-right: 4%;
    height: 94%;
    padding-top: 1%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    word-break: break-all;


}






















