:root {
    --color-first: white;
    --color-second: white;
    --color-third: black;
    --color-forth: white;
    --block-width: 100px;
    --block-height: 100px;
    --border-width: 0.625rem;
    --border-radius-outer: 8px;
    --border-radius-inner: calc(var(--border-radius-outer) / 2);
    --font-plain: "IBM Plex Sans", sans-serif;
    --font-special: "Fredoka One", sans-serif;
    box-sizing: border-box;
    line-height: 1.4;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  
  
  .rainbow {
    width: 100%;
    height: 100%;
    -webkit-animation: o-rotate-360 linear 2s infinite;
            animation: o-rotate-360 linear 2s infinite;
  }
  .rainbow span {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    transform: translate(-50%, -50%);
  }
  .rainbow span:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 100%;
  }
  .rainbow span:first-child {
    background: var(--color-first);
  }
  .rainbow span:first-child:after {
    background: var(--color-second);
  }
  .rainbow span:last-child {
    background: var(--color-third);
  }
  .rainbow span:last-child:after {
    background: var(--color-forth);
  }
  
  .c-subscribe-box {
    width: var(--block-width);
    height: var(--block-height);
    overflow: hidden;
    position: relative;
    box-shadow: 0 10px 40px -10px rgba(0, 64, 128, 0.2);
  }
  .c-subscribe-box__wrapper {
    width: calc(100% - var(--border-width));
    height: calc(100% - var(--border-width));
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  

  
  @-webkit-keyframes o-rotate-360 {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes o-rotate-360 {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  [type=submit] {
    margin-bottom: 0;
    font-family: var(--font-special);
    font-weight: normal;
    letter-spacing: 0.015em;
    font-size: 1.1rem;
  }
  [type=submit]:active {
    transform: scale(0.97);
  }
  
  





  /* new BAR */




/*    new */
.TransferBar{
  width: auto;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.container_transferBar {
  position: relative;
  width: 60vw;
  height: 35px;
}
.container_transferBar  .transfer_progressbar-container {
  position: relative;
  width: 100%;
  height: 30px;
  display: flex;
  border: 1px solid rgb(187, 187, 187);
  align-items: center;
  color: #fff;
  border-radius: 10px;
  justify-content: center;
  overflow: hidden;
  background: rgb(184, 184, 184);
}
.container_transferBar  .transfer_progressbar-container .transfer_progressbar-complete {
  position: absolute;
  left: 0;
  top: 0px;
  height: 100%;
  background-color: #5225bd;
  animation: f 2500ms infinite ease-in-out;
  border-radius: 10px;
  z-index: 2;
}
.container_transferBar  .transfer_progressbar-container .transfer_progressbar-complete .transfer_progressbar-liquid {
  z-index: 1;
  width: 70px;
  height: 70px;
  animation: f 2500ms infinite ease-in-out, t 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
  position: absolute;
  right: -5px;
  top: -10px;
  background-color: #5225bd;
  border-radius: 40%;
}
.container_transferBar  .transfer_progressbar-container .transfer_progress {
  z-index: 2;
  
}


.transfer_progressbar-complete{
  color: #fff;
}
@keyframes f {
  0% {
    background-color: rgb(53, 51, 51);
  }
  50% {
    background-color: rgba(93, 93, 93);
  }
  100% {
    background-color: rgb(53, 51, 51);
  }
}
@keyframes t {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}