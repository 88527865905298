

.progressbar_btn_div{
    display: flex;
    justify-content: center;
    align-items: center;
  

  }
  .upload_cancel{
    width: 8em;
    height: 2em;
    outline: none;
    background: none;
    color: rgb(63, 63, 63);
    font-weight: 600;
    font-size: 1em;
    background: #fff;
    border: none;
  }

  .upload_cancel:hover{
    cursor: pointer;
    background: #fff;
    color: black;
    border: none
  }