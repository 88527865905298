
.speedViewMain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: rgb(66, 66, 66);
    color: whitesmoke;
    font-size: 1.1em;
}

.backToAirChannel_div{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 22px;
    left: 22px;
    border-bottom: 1px solid whitesmoke;
    width: auto;
    height: auto;
    font-size: 0.8em;
}

.backToAirChannel_div:hover{
    cursor: pointer;
}

.prgressViewContainer{
    width: 90vW;
    height: 65vh;
    background: none;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.speedStartDiv{
    width: 100vw;
    height: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}
.speedStartBtn{
    outline: none;
    border: none;
    background: #fff;
    height: auto;
    width: 7em;
    font-size: 1em;
    color: black;
    font-weight: 300;
    border-radius: 2px;


}
.speedStartBtn:hover{
    cursor: pointer;
    background: rgba(180, 180, 180, 0.279);
    color: rgba(245, 245, 245, 0.637);
}

/* progress view */










.speedField{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 3px 3px;
}
.isRun{
    width: 100%;
    font-size: 1.1em;
    text-align: center;
    border-bottom: 1px solid white;
}

.speedTextCon{
    width: 100%;
    height: 3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    font-style: italic;

}








/*    new */

  
  .container_bar {
    width: 70vw;
    margin: 50px auto;
  }
  .container_bar .progressbar-container {
    position: relative;
    width: 100%;
    height: 50px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .container_bar .progressbar-container .progressbar-complete {
    position: absolute;
    left: 0;
    top: 0px;
    height: 100%;
    background-color: #fff;
    animation: g 2500ms infinite ease-in-out;
    z-index: 2;
  }
  .container_bar .progressbar-container .progressbar-complete .progressbar-liquid {
    z-index: 1;
    width: 70px;
    height: 70px;
    animation: g 2500ms infinite ease-in-out, r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
    position: absolute;
    right: -5px;
    top: -10px;
    background-color: #fff;
    border-radius: 40%;
  }
  .container_bar .progressbar-container .progress {
    z-index: 2;
  }
  
  @keyframes g {
    0% {
      background-color: #7db18c;
    }
    50% {
      background-color: #7db18c;
    }
    100% {
      background-color: #7db18c;
    }
  }
  @keyframes r {
    from {
      transform: rotate(0deg);
    }
    from {
      transform: rotate(360deg);
    }
  }