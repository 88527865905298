
.menu_icon{
    width: auto;
    height: auto;
  }


.Dropdown-root {
    position: relative;
    right: 12px
    
  }
  
  .Dropdown-control {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: relative;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.568);
    border-radius: 2px;
    box-sizing: border-box;
    cursor: default;
    outline: none;
  }
  
  .Dropdown-control:hover {
    border-radius: 2px;
    cursor: pointer;
  }


.Dropdown-menu {
    width: 200px;
    max-height:400px;
    background: linear-gradient(310deg, white 1%, transparent);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    border-radius: 2px;
    margin-top: -1px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    right: 0px;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }
  
  .Dropdown-menu .Dropdown-group > .Dropdown-title {
    padding: 8px 10px;
    color: rgba(51, 51, 51, 1.2);
    font-weight: bold;
    text-transform: capitalize;
  }
  
  .Dropdown-option {
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-size: 1.1em;
    cursor: pointer;
    display: block;
    padding: 8px 10px;
  }

  .Dropdown-option:hover {
    background-color: rgba(167, 159, 159, 0.13);
  }


  .test{
      width: 0;
      height: 0;
      color: transparent
  }