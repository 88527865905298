


.File_list_frame{
    width: 100%;
    height: 100%;
    overflow: hidden;

}


.list_item{
    margin-top: 2.5%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    


}

.list_item_folder{
    width: 100%;
    margin-top: 2.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    


}

















/* item */
.fileList_item_frame{
    height: 50px;
    width: 95%;
    border-radius: 2px;
    margin-bottom: 0.5vh;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow: hidden; 



}
.item_frame::-webkit-scrollbar {
    display: none;
}

.folder_frame_div{
    height: 9vh;
    width: 95%;
    background: #fff;  /* item backround !!!! */
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;


}

.item_frame{
    height: 9vh;
    width: 95%;
    background: #fff;  /* item backriund !!!! */
    border-radius: 2px;
    margin-bottom: 0.5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;


}

.item_icon_div{
    width: 12%;
    margin-left: 0.6vh;
}

.item_icon{
    
    width: 40px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.item_name_div{
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    max-height: 98%;
    height: auto;
    font-size: 0.8em;
    break-inside: auto;
    overflow: hidden;

}

.item_remove_div{
    width: 30px;
    height: 32px;
    background: none;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.6vh;
    
    

}

.item_remove{
    background: none;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    font-weight: 700;
    font-size: 1.2em;

}
.item_remove:hover{
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.123);
    border-radius: 2px;

}


