


.view_finish{
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

}

.view_finish_mobile{
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

}

.div_link{
    width: 90%;
    height: 90%;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

}





.link_text{
    width: 100%;
    height: 40px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 400;
    color: black;
    border-bottom: 1px solid black;

    
}
.link_text_2{
    width: 100%;
    height: 40px;
    margin: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-style: italic;
    font-weight: 500;
}

.email_text{
    width: 100%;
    height: 40px;
    margin: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    
}



.link_copy{
    width: 80%;
    height: auto;
    padding: 10px 10px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 2px;
    color: rgb(0, 0, 0);
    word-wrap: break-word;
    background: rgba(245, 245, 245, 0.774);
  
}

.link_copy:hover{
    cursor: pointer;
    color: rgb(6, 108, 241);
    border: 1px solid rgb(6, 108, 241);
}

.share_div{
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.icon_btn:hover{
    cursor: pointer;
}











