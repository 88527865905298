




.send_view_modal{
    position: absolute;
    bottom: 0px;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 420px;
    height: 100vh;
    overflow: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    background: white;  /* Firefox */
}


.send_view_modal_mobile{
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    overflow-y: scroll;
    background: white;
}

.send_view_frame{
    position: relative;
    width: 94%;
    height: 96%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    margin: 0 auto; 
}

.send_view_frame_mobile{
    position: relative;
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.send_view_title_div{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-weight: 600;
    width: auto;
    height: auto;
    margin: 0 auto;
    text-align: center;
    
}

.send_view_div{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    width: 100%;
    height: auto;
    margin: 0 auto;
    text-align: center;
    
}

.sendview_info_text{
    width: 95%;
    height: 5em;
    font-style: italic;
    font-weight: 400;
    font-size: 0.8em;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.4em;
    padding-right: 0.4em;

}

.send_view_change_zone{
    width: 100%;
    height: auto;
}

.send_view_change_zone_div{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: large;
    width: 100%;
    height: 100%;

}

.send_view_radio_div{
    width: 95%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}


.send_view_show_options{
    width: 98%;
    height: 10em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.send_view_formlabel{
    color: black;
}

.send_view_div_linktext{
    width: auto;
    height: 100%;
    color: black;
    font-weight: 700;
    border-bottom: 1px solid black;
}





.send_view_div_input{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 0.8em;
    font-style: italic;
    color: black;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
}
.send_view_input{
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(71, 71, 71);
    font-size: 1em;
    background: none;
    color: black;
    font-weight: 600;
    outline: none;
    font-family: inherit;
    height: 1.6em;
}

.send_view_message{
    font-family: inherit;
    width: 98%;
    height: auto;
    padding: 1em ;
    border: 1px solid rgba(112, 112, 112, 0.664);
    border-radius: 2px;
    font-size: large;
    color: black;
    outline: none;
    resize: none;
    background: whitesmoke;
    font-weight: 400;



}


.send_view_btn_div{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: large;
    height: 3em;
    margin: 0 auto;
    text-align: center;
    width: 98%;
}



.send_btn{
    height: 2.3em;
    width: 5em;
    outline: none;
    background: rgba(29, 29, 29, 0.801);
    color: white;
    font-size: 1.1em;

    font-weight: 500;
    border: none;
    vertical-align: bottom;
    border: 1px solid black;
    border-radius: 0.4em;



}

.send_btn:hover{
    cursor: pointer;
    background: black;
    
}







textarea::-webkit-input-placeholder {
    color: rgb(59, 59, 59);
    font-size: 1em;
    font-style: italic;
  }
  
  textarea:-moz-placeholder { /* Firefox 18- */
    color: rgb(59, 59, 59);
    font-size: 1em;
    font-style: italic; 
  }
  
  textarea::-moz-placeholder {  /* Firefox 19+ */
    color: rgb(59, 59, 59);
    font-size: 1em;
    font-style: italic; 
  }
  
  textarea:-ms-input-placeholder {
    color: rgb(59, 59, 59);
    font-size: 1em;
    font-style: italic; 
  }
  
  textarea::placeholder {
    color: rgb(59, 59, 59);
    font-size: 1em;
    font-style: italic; 
  }