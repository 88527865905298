

.download_icon_div{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid black;
    border-radius: 3px;
    max-height: 100px;
    max-width: 100px;
    margin: 15px 15px;
}

.download_icon_div:hover{
    cursor: pointer;
}

.download_list_view{
    position: absolute;
    height: auto;
    width: 20vw;
    min-width: 300px;
    max-height: 55vh;
    bottom: 13vh;
    left: 1vh;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 1vh;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.download_list_view::-webkit-scrollbar {
    display: none;
}


.download_message_text{
    height: 3vh;
    width: 20vw;
    min-width: 300px;
    bottom: 66vh;
    left: 1vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    color: black;
    
}


.download_message_view{
    display: flex;
    flex-direction: column;
    height: 15vh;
    width: 20vw;
    min-width: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 1vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div_input_upload_progressbar_full{
    width: 100%;
    height: 100%;
}

.download_info_btn_div{
    width: auto;
    margin-left: 1em;
}
.download_info_btn_div:hover{
    cursor: pointer;
}


.text_input_upload_NO_CONTENT{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 2em;
}

.progressbar_view_download{
    position: absolute;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}