
.helpView{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: black;
    color: whitesmoke;
    font-size: 1.1em;
}

.backToAirChannel_div{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 22px;
    left: 22px;
    border-bottom: 1px solid whitesmoke;
    width: auto;
    height: auto;
    font-size: 0.8em;
}

.backToAirChannel_div:hover{
    cursor: pointer;
}