


.app_window{
  height: 500px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  
}

.head_view{
  position: absolute;
  top: 10px;
  width: 100vw;
  height: 5vh;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.air_icon{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
}
.title{
  width: 100%;
  height: 5vh;
  justify-content: center;
  align-items: center;
  color: aliceblue;
  font-size: x-large;
  font-weight: 300;
  text-align: center;
  display: flex;
  text-shadow: 2px 2px 4px #000000;
}

.menu{
  position: absolute;
  width: auto;
  height: auto;
  text-align: right;
  top: 12px;
  right: 12px;
}



.actions_view{
  position: absolute;
  width: 100vw;
  height: 100px;
  bottom: 0;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.unkown_view{
  height: 100%;
  width: 100%;
}













.open_beta{
  position: absolute;
  right: 60vw;
  top: 0px;
  width: auto;
  height: auto;
  font-style: italic;
  font-weight: 400;
  font-size: 1em;
  color: black;
}