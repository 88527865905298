.rodal_div{
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 100vh;
  width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}



.frame_input_upload{
    width: 100%;
    background: linear-gradient(0.2deg, white 85%, transparent);
    height: 100px;
  }

.div_input_upload{
  width: auto;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}  
    
.label_input_upload{
      bottom: 20px;
      right: 20px;
      width: 100%;
      height: 100%;
      max-height: 100px;
      max-width: 100px;
      width: auto;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 15px 15px;
      
    }
    .label_input_upload:hover{
      cursor: pointer;
    }
    
    .input_upload{
      display: none;
    }
    
    
  .text_input_upload{
    word-wrap: unset;
    width: auto;
    font-weight: 600;

   

  }
  .text_input_upload_size{
    word-wrap: unset;
    width: 9em;
    font-weight: 600;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  .change_input_div{
    width: 3em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
  }

  .change_input_div:hover{
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.123);
    border-radius: 2px;
  }
  
  

  .ready_to_send_div{
    height: 100%;
    width: 175px;
    display: flex;
    justify-content: center;
    align-items: center;


  }

  .upload_icon_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border: 3px solid black;
    outline: none;
    background: none;
    border-radius: 2px;
    font-weight: bold;
    font-size: large;

  }
  .upload_icon_btn:hover{
    cursor: pointer;
    background: rgba(255, 255, 255, 0.424);

  }
  
  


  .upload_list{
    position: absolute;
    height: auto;
    width: 20vw;
    max-height: 35vh;
    min-width: 300px;
    bottom: 13vh;
    left: 1vh;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 2px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .upload_list::-webkit-scrollbar {
    display: none;
  }

  .upload_finish{
    position: absolute;
    height: 370px;
    width: 20vw;
    min-width: 300px;
    bottom: 13vh;
    left: 1vh;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid rgba(0, 0, 0, 0.105);
    border-radius: 2px;

    
  }
  .upload_finish_mobile{
    position: absolute;
    z-index: 25;
    height: 50vh;
    width: 100vw;
    bottom: 13vh;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid rgba(0, 0, 0, 0.105);

    
  }



  .progressbar_view{
    position: absolute;
    width: 100vw;
    bottom: 0px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
  }
  .cancel_place{
    position: relative;
    width: auto;
    margin-right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  